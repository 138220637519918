<template>
  <video controls preload="none">
    <source :src="proxyUrl" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</template>

<script setup lang="ts">
// TODO:  Fix playing of uploaded videos, they can be proxies on cloudinary like this: https://res.cloudinary.com/klandestino-ab/video/fetch/https://cms.djurensratt.se/sites/default/files/2024-03/hur-ar-det-att-vara-volontar-hos-djurens-ratt_.mp4
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

const { cmsBasePath } = useRuntimeConfig().public

const props = defineProps({
  url: String,
})

const proxyUrl = computed(() => {
  return `https://res.cloudinary.com/klandestino-ab/video/fetch/${cmsBasePath + props.url}` || ''
})
</script>
