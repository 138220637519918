<template>
  <NuxtLink
    class="relative rounded-md py-3 font-sans text-base font-bold uppercase outline-0 transition lg:py-4 lg:text-xl"
    :class="[
      buttonClass,
      !arrowRight
        ? 'inline-block px-8 text-center lg:px-14'
        : 'flex items-center justify-center px-2',
    ]"
    :to="removeCmsDomain(link)"
    :target="external ? '_blank' : '_self'"
  >
    <span :class="classNameText">{{ title }}</span>
    <ArrowRight v-if="arrowRight" class="ml-4" />
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ArrowRight from './Icons/ArrowRight.vue'
import { removeCmsDomain } from '../../utils/urlFilter'
const props = defineProps({
  title: String,
  classNameText: String,
  type: {
    type: String,
    default: 'fill',
  },
  color: {
    type: String,
    default: 'blue',
  },
  link: String,
  external: Boolean,
  arrowRight: { type: Boolean, default: false },
})

const buttonClass = computed(() => ({
  'bg-blue text-white hover:bg-blue-dark border-2 border-blue hover:border-blue-dark shadow-[0_5px_15px_0px_rgba(0,155,181,0.22)] hover:shadow-none':
    props.type === 'fill' && props.color === 'blue',
  'bg-white text-blue border-2 border-blue hover:border-blue-dark hover:text-blue-dark shadow-[0_5px_15px_0px_rgba(0,155,181,0.22)] hover:shadow-none':
    props.type === 'outline' && props.color === 'blue',
  'bg-pink text-white hover:bg-pink-dark border-2 border-pink hover:border-pink-dark shadow-[0_5px_15px_0px_rgba(171,8,98,0.22)] hover:shadow-none':
    props.type === 'fill' && props.color === 'pink',
  'bg-white text-pink border-2 border-pink hover:border-pink-dark hover:text-pink-dark shadow-[0_5px_15px_0px_rgba(171,8,98,0.22)] hover:shadow-none':
    props.type === 'outline' && props.color === 'pink',
}))
</script>
