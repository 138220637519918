<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="w-full lg:max-w-[430px] 2xl:max-w-[630px]">
    <div class="px-4 lg:px-0">
      <ClientOnly fallback-tag="span" fallback="Loading ...">
        <Swiper
          class="tabs"
          :slides-per-view="'auto'"
          :space-between="95"
          :mousewheel="true"
          :breakpoints="breakpoints"
          :modules="[Mousewheel, Controller]"
          :controller="{ control: controlledSwiperTab }"
          @swiper="setSwiperTab"
        >
          <SwiperSlide v-for="(item, index) in tabs" :key="index">
            <div class="flex py-4 hover:text-blue">
              <span
                class="relative block cursor-pointer font-sans text-base font-bold before:absolute before:-bottom-[16px] before:left-0 before:h-0.5 before:w-[1000%] before:bg-blue-line before:content-[''] after:absolute after:-bottom-[16px] after:left-0 after:z-10 after:h-0.5 after:w-full after:content-[''] lg:text-xl"
                :class="[index === activeTab && 'text-blue after:bg-blue']"
                @click="setActiveTab(index)"
              >
                {{ item.tabLabel }}
              </span>
            </div>
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>

    <slot name="content"></slot>
    <div class="px-4 lg:mt-6 lg:px-0">
      <ClientOnly fallback-tag="span" fallback="Loading ...">
        <Swiper
          :slides-per-view="1"
          :space-between="0"
          class="tabs-content"
          :allow-touch-move="false"
          :modules="[Controller]"
          :controller="{ control: controlledSwiper }"
          @swiper="setSwiper"
        >
          <Swiper-slide v-for="(item, index) in tabs" :key="index">
            <div>
              <h3 class="mb-4 font-sans text-base font-bold lg:text-xl">
                {{ item.title }}
              </h3>
              <div
                class="mb-8 font-sans text-sm leading-normal lg:text-base"
                v-html="item.copy"
              ></div>

              <LinkSecondary
                v-if="item?.link?.url"
                :link="item?.link?.url"
                :title="item?.link?.title"
                type="blue"
                :external="item.link?.type === 'ext'"
              />
            </div>
          </Swiper-slide>
        </Swiper>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Swiper as SwiperType } from 'swiper'
import { type SwiperOptions } from 'swiper/types'
import { Controller, Mousewheel } from 'swiper/modules'

import LinkSecondary from '../atoms/LinkSecondary.vue'

import type { ILinkData } from '../../types'

export interface ITab {
  title?: string
  copy?: string
  link?: ILinkData
  tabLabel?: string
}

interface Props {
  tabs?: ITab[]
}

defineProps<Props>()

const activeTab = ref(0)
const controlledSwiper = ref<SwiperType | undefined>()
const controlledSwiperTab = ref<SwiperType | undefined>()

const setActiveTab = (index: number) => {
  activeTab.value = index
  controlledSwiper.value?.slideTo(index)
  controlledSwiperTab.value?.slideTo(index)
}

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
}

const setSwiperTab = (swiper: SwiperType) => {
  controlledSwiperTab.value = swiper
}

const breakpoints: SwiperOptions['breakpoints'] = {
  320: {
    slidesPerView: 'auto',
    spaceBetween: 18,
  },
  1024: {
    slidesPerView: 'auto',
    spaceBetween: 50,
  },
}
</script>

<style>
.tabs .swiper-slide {
  width: auto;
}

.tabs,
.tabs-content {
  padding-bottom: 1em !important;
}

.tabs .swiper-slide {
  z-index: 100;
}
</style>
