<template>
  <div
    v-if="tall"
    class="relative aspect-[440/650] w-full self-start overflow-hidden rounded-2xl md:aspect-video lg:rounded-none"
  >
    <component
      :is="component"
      :url="data?.video?.url || data?.video?.file"
      class="absolute h-full w-full"
    />
  </div>
  <component
    :is="component"
    v-else
    :url="data?.video?.url || data?.video?.file"
    class="aspect-video w-full self-start sm:rounded-2xl lg:rounded-none"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import VideoIframe from '../atoms/VideoIframe.vue'
import VideoPlayer from '../atoms/VideoPlayer.vue'

export interface IVideoBlock {
  id: string
  video?: {
    url: string
    file: string
  }
}

interface Props {
  data: IVideoBlock
  tall: boolean
}

const props = withDefaults(defineProps<Props>(), {
  tall: () => false,
})

const component = computed(() => (props?.data?.video?.url ? VideoIframe : VideoPlayer))
</script>
