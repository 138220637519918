import { parse } from 'yaml'
import { getRelationships } from './getRelationships'
import deepClone from '../../utils/deepClone'
import { FormType } from '../../types'

type getFormReturnType = FormType | undefined

export const getForm = (data: any): getFormReturnType => {
  if (!data) return

  const copy = deepClone(data)

  const id = Array.isArray(copy) && copy.length > 0 ? copy[0].id : copy?.id

  if (copy.length === 0) return

  const form = getRelationships(id)

  if (!form) return

  const elementYaml = form?.attributes?.elements || undefined
  const parsedYaml = parse(elementYaml)
  const elements = Object.entries(parsedYaml)
  return {
    id: form?.attributes?.drupal_internal__id,
    title: form?.attributes?.title,
    description: form?.attributes?.description,
    elements,
    confirmation: {
      title: form?.attributes?.settings?.confirmation_title || '',
      message: form?.attributes?.settings?.confirmation_message || '',
    },
    status: form?.attributes?.status,
    form_close_message: form?.attributes?.settings?.form_close_message,
    form_open_message: form?.attributes?.settings?.form_open_message,
    open: form?.attributes?.open,
    close: form?.attributes?.close,
  }
}
