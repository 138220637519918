import { default as _91_46_46_46slug_936jkaE6IT4XMeta } from "/opt/render/project/src/apps/refresh/pages/[...slug].vue?macro=true";
import { default as search1XTvFeU5TyMeta } from "/opt/render/project/src/apps/refresh/pages/search.vue?macro=true";
import { default as rejectIhWLIRnGNKMeta } from "/opt/render/project/src/apps/refresh/pages/swish/reject.vue?macro=true";
import { default as success6tcPTw18poMeta } from "/opt/render/project/src/apps/refresh/pages/swish/success.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_936jkaE6IT4XMeta?.name ?? "slug",
    path: _91_46_46_46slug_936jkaE6IT4XMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_936jkaE6IT4XMeta || {},
    alias: _91_46_46_46slug_936jkaE6IT4XMeta?.alias || [],
    redirect: _91_46_46_46slug_936jkaE6IT4XMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/refresh/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: search1XTvFeU5TyMeta?.name ?? "search",
    path: search1XTvFeU5TyMeta?.path ?? "/search",
    meta: search1XTvFeU5TyMeta || {},
    alias: search1XTvFeU5TyMeta?.alias || [],
    redirect: search1XTvFeU5TyMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/refresh/pages/search.vue").then(m => m.default || m)
  },
  {
    name: rejectIhWLIRnGNKMeta?.name ?? "swish-reject",
    path: rejectIhWLIRnGNKMeta?.path ?? "/swish/reject",
    meta: rejectIhWLIRnGNKMeta || {},
    alias: rejectIhWLIRnGNKMeta?.alias || [],
    redirect: rejectIhWLIRnGNKMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/refresh/pages/swish/reject.vue").then(m => m.default || m)
  },
  {
    name: success6tcPTw18poMeta?.name ?? "swish-success",
    path: success6tcPTw18poMeta?.path ?? "/swish/success",
    meta: success6tcPTw18poMeta || {},
    alias: success6tcPTw18poMeta?.alias || [],
    redirect: success6tcPTw18poMeta?.redirect,
    component: () => import("/opt/render/project/src/apps/refresh/pages/swish/success.vue").then(m => m.default || m)
  }
]