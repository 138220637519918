const removeDomains = [
  'https://cms.project1882.org',
  'https://cms.djurensratt.se',
  'https://cms-ua.project1882.org',
  'https://cms-es.project1882.org',
  'https://stage-internal.djurensratt.se',

  'http://cms.project1882.org',
  'http://cms.djurensratt.se',
  'http://cms-ua.project1882.org',
  'http://cms-es.project1882.org',
  'http://stage-internal.djurensratt.se',

  'cms.project1882.org',
  'cms.djurensratt.se',
  'cms-ua.project1882.org',
  'cms-es.project1882.org',
  'stage-internal.djurensratt.se',
] as const

export const removeCmsDomain = (url: string | undefined): string => {
  const rec = url
  if (!url || typeof url === 'undefined') return ''
  if (typeof url !== 'string') return ''

  if (typeof url === 'string') {
    removeDomains.forEach(domain => {
      // @ts-ignore
      url = url.replace(domain, '')
    })
  } else return ''
  if (rec !== url) console.log('refreshui fixing url: ', { was: rec, now: url })
  return url
}
